import React from 'react';

import Locker from './locker';
import { pr } from './common';

export default class Prompt extends React.Component {
  state = { prompt: this.props.defaultValue }

  constructor(props) {
    super(props);

    this.checkInput = ("function" == typeof this.props.checkInput)
      ? this.props.checkInput
      : function(value) { return (/\S/.test(value)); };
  }

  okClick = e => {
    pr(e);
    if(this.locker) this.locker.setLock(true);

    if ("function" == typeof this.props.okClick) this.props.okClick(this.state.prompt);
    this.ok_done();
  }

  ok_done = () => {
    if(this.locker) this.locker.setLock(false);
    this.closeModal();
  }

  change_prompt = e => this.setState({prompt: e.target.value})

  closeModal = e => {
    pr(e);
    window.modal("hide");
  }

  render() {
    let disableSubmit = this.props.forceInput && !this.checkInput(this.state.prompt),
        prefix = this.props.prefix ? this.props.prefix + '-' : '',
        isTiny = this.props.type === 'tiny',
        errorMsg;

    if (typeof this.props.getErrorValidate === 'function' && this.props.getErrorValidate(this.state.prompt)) {
      disableSubmit = true;
      if (this.props.errorMsg) errorMsg = this.props.errorMsg;
    }

    const placeholder = isTiny ? this.props.label : this.props.placeholder;

    return (
      <div className={classNames("modal-dialog", {"_tiny": isTiny})}>
        <form className="modal-content" onSubmit={this.okClick}>
          <div className="modal-header">
            {!isTiny && <a href="#" className="close-icon" onClick={this.closeModal}/>}
            <h4>{this.props.header}</h4>
          </div>

          <div className="modal-body _no_min_height">
            <div className="modal-body-transparent_wrapper">
              <div className={classNames("labeled_row", {"_label_above": !isTiny, "_no_label": isTiny})}>
                {!isTiny && <div className="__label">{this.props.label}</div>}
                <div className="__body">
                  <input
                    className={errorMsg ? "_error" : undefined}
                    type={this.props.inputType}
                    defaultValue={this.props.defaultValue}
                    autoFocus={this.props.no_autofocus ? false : true}
                    onChange={this.change_prompt}
                    placeholder={placeholder}
                  />
                </div>
              </div>
              {errorMsg}
            </div>
            {this.props.children}
          </div>

          <div className="modal-footer">
            {this.props.footerLink && <div className="modal-footer-left_row">{this.props.footerLink}</div>}
            <div className="modal-footer-right_row">
              <a className="sub_btn" href="#" onClick={this.closeModal}>{this.props.cancelBtn}</a>
              <Locker ref={(ref) => this.locker = ref}>
                <input
                  type="submit"
                  id={prefix+'submit-button'}
                  value={this.props.okBtn}
                  disabled={disableSubmit}
                  className="sub_btn _blue"
                />
              </Locker>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Prompt.defaultProps = {
  defaultValue: "",
  inputType: "text",
  okBtn: "Create",
  cancelBtn: "Cancel",
  forceInput: false
};
