const CONSTANTS = {
  ACCESS_LEVEL: {
    READ: 'read',
    OWN: 'own',
    WRITE: 'write',
    LOOK: 'look',
    REVOKED: 'revoked'
  },
  OBJECT_TYPE: {
    FILE: 'file',
    FOLDER: 'folder',
    PACKAGE: 'package',
    PROJECT: 'project',
    MESSAGE: 'message',
    USER: 'user',
    RFI: 'rfi',
    TRASH: 'trash'
  },
  PACKAGE_STATUS: {
    LIVE: 'live',
    DELETED: 'deleted',
    HIDDEN: 'hidden',
    GONE: 'gone',
    PUBLISHED: 'published',
    SENT: 'sent'
  },
  PROJECT_STATUS: {
    LIVE: 'live',
    DELETED: 'deleted',
    HIDDEN: 'hidden',
    GONE: 'gone',
    ARCHIVED: 'archived',
    PUBLISHED: 'published',
    SENT: 'sent'
  },
  FILE_STATUS: {
    LIVE: 'live',
    DELETED: 'deleted',
    DELETED_RECUR: 'deleted-recur',
    HIDDEN: 'hidden',
    GONE: 'gone'
  },
  FILE_ROLE: {
    LOGO: 'logo',
    SPECBOOK: 'specbook',
    STAMP: 'stamp',
    COVER: 'cover',
    SEGMENT: 'segment'
  },
  LOGITEM_STATUS: {
    UNASSIGNED: 'unassigned',
    DELETED: 'deleted',
    ASSIGNED: 'assigned',
    CLOSED: 'closed'
  },
  USER_STATUS: {
    INITIAL: 'initial',
    LIVE: 'live',
    DELETED: 'deleted',
    HIDDEN: 'hidden',
    GHOST: 'ghost',
    LOCKED: 'locked'
  },
  COMPANY_STATUS: {
    INITIAL: 'initial',
    LIVE: 'live',
    CANCELED: 'canceled',
    PAYMENT: 'payment',
    PAST_DUE: 'past_due',
    GHOST: 'ghost'
  },
  COMPANY_MODE: {
    TRIAL: 'trial',
    PAIDSUB: 'paidsub',
    NOSUB: 'nosub',
    ADMIN: 'admin',
    GHOST: 'ghost'
  },
  RFI_STATUS: {
    DELETED: 'deleted',
    GONE: 'gone',
    HIDDEN: 'hidden',
    LIVE: 'live'
  },
  LIBRARY_ACCESS_TYPES: {
    NONE: 'none',
    READ: 'read',
    WRITE: 'write',
    DEFAULT: 'read'
  },
  EVENT_TYPE: {
    SIGN_UP: 'sign-up',
    CONFIRM: 'confirm',
    LOGIN: 'login',
    LOGIN_FAIL: 'login-fail',
    LOGOUT: 'logout',
    CREATE: 'create',
    UPLOAD: 'upload',
    SEND: 'send',
    RESEND: 'resend',
    FORWARD: 'forward',
    APPROVE: 'approve',
    DUPLICATE: 'duplicate',
    VIEW_EXTERNAL: 'view-external',
    DOWNLOAD_EXTERNAL: 'download-external',
    DOWNLOAD: 'download',
    ANNOTATE: 'annotate',
    DELETE: 'delete',
    RESTORE_DELETED: 'restore-deleted',
    DELETE_FOREVER: 'delete-forever',
    ERROR: 'error',
    ADD: 'add',
    MOVE: 'move',
    REMOVE: 'remove',
    UNAPPROVE: 'unapprove',
    RENAME: 'rename',
    SETEUID: 'seteuid',
    UNSETEUID: 'unseteuid',
    BOUNCE_HARD: 'bounce-hard',
    BOUNCE_SOFT: 'bounce-soft',
    EMAILCONFIRM: 'emailconfirm',
    SHARE: 'share',
    UNSHARE: 'unshare',
    SETOWNER: 'setowner',
    ASSIGN: 'assign',
    UNASSIGN: 'unassign',
    SUBMIT: 'submit',
    RETURN: 'return',
    SELF_ASSIGN: 'self-assign',
    PACKAGE_RETURNED_UPLOAD: 'package-returned-upload',
    REVIEW_COLLABORATE: 'review-collaborate',
    COLLABORATE_START: 'collaborate-start',
    COLLABORATE_SUBMIT: 'collaborate-submit',
    COLLABORATE_END: 'collaborate-end',
    NEED_BY_DATE_CHANGED: 'need-by-date-changed',
    DUE_DATE_CHANGED: 'due-date-changed',
    ARCHIVE: 'archive',
    UNARCHIVE: 'unarchive',
    SPLIT: 'split',
    UNSUBMIT: 'unsubmit',
    RESUBMIT: 'resubmit',
    PUBLISH: 'publish',
    REPUBLISH: 'republish',
    SUBMITTED_OUTSIDE: 'submitted-outside',
    PACKAGE_PDF_UPLOAD: 'package-pdf-upload',
    PACKAGE_RETURNED_VERBAL: 'package-returned-verbal',
    VISIT: 'visit',
    REQUEST: 'request',
    REVISION_REQUEST: 'revision-request',
    CLOSE: 'close',
    UNREQUEST: 'unrequest',
    UPLOAD_OUTSIDE_RESPONSE: 'upload-outside-response'
  },
  SEND_ACTION: {
    ASSIGN: 'assign',
    UNASSIGN: 'unassign',
    SUBMIT: 'submit',
    RETURN: 'return',
    FORWARD: 'forward',
    ROUTING: 'routing',
    COLLABORATE_START: 'collaborate-start',
    COLLABORATE_SUBMIT: 'collaborate-submit',
    PUBLISH: 'publish',
    REQUEST: 'request'
  },
  ERROR_CODES: {
    404: 404,
    ACCESS_CANCELED: 'access-canceled',
    ACCESS_DENIED: 'access-denied',
    AUTHENTICATION_FAIL: 'authentication-fail',
    AUTHENTICATION_FAIL_LOCK_LAST_WARN: 'authentication-fail-lock-last-warn',
    AUTHENTICATION_FAIL_LOCK_WARN: 'authentication-fail-lock-warn',
    AUTHENTICATION_FAIL_LOCKED: 'authentication-fail-locked',
    AUTHENTICATION_FAIL_LOCKED_NOW: 'authentication-fail-locked-now',
    BAD_DOCUMENT: 'bad-document',
    CANT_REUSE_PASSWORD: 'cant-reuse-password',
    DOCUMENT_IS_READ_ONLY: 'document-is-read-only',
    DOCUMENT_NOT_FOUND: 'document-not-found',
    DOWNLOAD_FAIL: 'download-fail',
    FEEDBACK_IS_LACKING: 'feedback-is-lacking',
    FILE_OCR_FAILED: 'file-ocr-failed',
    FILE_TOO_BIG: 'file-too-big',
    INPUT_ACCOUNT_EXISTS: 'input-account-exists',
    INPUT_BAD_VALUE: 'input-bad-value',
    INPUT_MISSING: 'input-missing',
    INPUT_PASSWORD_INCORRECT: 'input-password-incorrect',
    INPUT_PASSWORD_INCOMPLETE: 'input-password-incomplete',
    INVALID_INPUT_FILE: 'invalid-input-file',
    INVALID_OP: 'invalid-op',
    INVALID_PARAMS: 'invalid-params',
    NON_WRITEABLE_LEVEL: 'non-writeable-level',
    NOT_ENOUGH_CAN: 'not-enough-can',
    PASSWORD_EXPIRED: 'password-expired',
    PREVIEW: 'preview',
    PROJECT_READ_ONLY: 'project-read-only',
    PROVIDED_BY_DISALLOW: 'provided-by-disallow',
    PROVIDED_BY_TAKEN: 'provided-by-taken',
    RECOVER_ERROR: 'recover_error',
    RECOVER_EXPIRED: 'recover_expired',
    SAMPLE_DATA_LOCK: 'sample-data-lock',
    SPLIT_FAIL: 'split-fail',
    STORAGE_UPLOAD_FAIL: 'storage-upload-fail',
    VERIFY_PARAMS_INCORRECT: 'verify-params-incorrect',
    WORKFLOW_CIRCULAR: 'workflow-circular',
    WORKFLOW_YOURSELF: 'workflow-yourself',
    RESPONSE_NEEDED: 'response-needed',
    PROJECT_NECESSARY: 'project-necessary',
    EOTP_CODE_ATTEMPTS: 'eotp-code-attempts',
    EOTP_CODE_EXPIRED: 'eotp-code-expired',
    EOTP_CODE_WAITING: 'eotp-code-waiting',
    MFA_TOKEN_INVALID: 'mfa-token-invalid',
    MFA_TOKEN_REQUIRED: 'mfa-token-required',
    USER_DEACTIVATED: 'user-deactivated',
    USER_NOT_FOUND: 'user-not-found',
    PASSWORD_INCORRECT: 'password-incorrect',
    ACTIVATION_APPROVAL_PENDING:'activation-approval-pending',
    ACTIVATION_PENDING: 'activation-pending',
    FT_SEARCH_SYSTEM_FAIL: 'ft-search-system-fail'
  },
  PACKAGE_FLOW_STATE: {
    APPROVED: 'approved',
    ASSIGNED: 'assigned',
    UNASSIGNED: 'unassigned',
    DELETED: 'deleted',
    FEEDBACK: 'feedback',
    FEEDBACK_OUTSIDE: 'feedback-outside',
    INITIAL: 'initial',
    REVIEWED: 'reviewed',
    SUBMITTED: 'submitted',
    UNSUBMITTED: 'unsubmitted',
    ORPHAN_DELETED: 'orphan-deleted',
    SELF_ASSIGNED: 'self-assigned'
  },
  PACKAGE_ROW_FLOW_STATE: {
    APPROVED: 'approved',
    ASSIGNED: 'assigned',
    ASSIGNED_DELETE: 'assigned:delete',
    DELETED: 'deleted',
    FEEDBACK: 'feedback',
    INITIAL: 'initial',
    REVIEWED: 'reviewed'
  },
  RFI_FLOW_STATE: {
    INITIAL: 'initial',
    OPEN: 'open',
    ORPHAN: 'orphan',
    RESOLVED: 'resolved',
    RESPONDED: 'responded'
  },
  PACKAGE_CLASS: {
    SUBMITTAL: 'submittal',
    CLOSEOUT: 'closeout',
    COMPOSITE: 'composite'
  },
  SUPAED_FEATURES: {
    SPEC_UPLOAD_ADD_FROM: 'spec_upload_add_from',
    UPLOAD_OUTSIDE_RETURNED: 'upload_outside_returned',
    UPLOAD_OUTSIDE_SUBMITTED: 'upload_outside_submitted',
    UPLOAD_INCOMING_SUBMITTAL: 'upload_incoming_submittal',
    SHOW_HISTORY_REVISIONS: 'show_history_revisions',
    RFI_CREATE: 'rfi_create'
  },
  NETWORK_ERROR: {
    NETWORK_UNAVAILABLE: 'network-unavailable',
    SERVER_UNAVAILABLE: 'server-unavailable',
    SERVER_INTERNAL: 'server-internal',
    UNAUTHENTICATED: 'unauthenticated',
    REQUEST_TIMEOUT: 'request-timeout',
    OTHER: 'other'
  },
  MFA_TYPES: {
    EOTP: 'eotp',
    TOTP: 'totp'
  },
  REGEXP_INVITE_CODE: /[\/=](\w{8}-(?:\w{4}-){3}\w{12})\/?/,
  MAX_TITLE_LENGTH: 255,
  GRACE_TO_DOWNLOAD_DAYS: 60,
  ADMIN_HISTORY_DEFAULT_LIMIT: 200
};

Object.keys(CONSTANTS).forEach(x => {
  CONSTANTS[x] = Object.freeze(CONSTANTS[x]);
});

module.exports = {
  ...CONSTANTS,
  generate: (arr = []) => Object.freeze(arr.reduce((acc, x) => {
    acc[x.replace('-', '_').toUpperCase()] = x;

    return acc;
  }, {}))
};
