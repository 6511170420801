import DownloadFailDlg from './dialog/download-fail-dlg';
import { ACCESS_LEVEL, FILE_ROLE } from '../api/constants';

export { isDeleted, prettyName, capitalize } from '../business/primal';

export const not_implemented = { style: { display: "none" } };


export function walkThrough(object, path) {
    var a = path.split("/");

    for (var i = 0, n = a.length; i < n; ++i) {
        var res = a[i].split("#");
        var k = res[0],
            id = res[1];

        if (k in object) {
            object = object[k];
        } else {
            return null;
        }
        if (id) {
            var node = null;
            for (var j = 0, l = object.length; j < l; ++j) {
                if (object[j].id == id) {
                    node = object[j];
                    break;
                }
            }
            if (node) {
                object = node;
                continue;
            }
            return null;
        }
    }
    return object;
};


export function clean_file(file) {
  var allowed_fields = [
    'id',
    'file_id',
    'name',
    'url',
    'mime_type',
    'file_size',
    'is_annotatable',
    'annotation',
    'page_count',
    'flags'
  ];

  var r = {};
  allowed_fields.forEach(function(i){ if (typeof file[i] !== "undefined" && file[i] != null ) r[i] = file[i] });
  if (file.auto_ctime) r.upload_time = file.auto_ctime;
  return r;
};


function _cacheFilter(_pool, _term, opts) {
  let _pattern = _term.toLowerCase().replace(/\s+/g, '');
  let _filtered = _.filter(_pool, _item => _item.label && (!_item.label.toLowerCase().replace(/\s+/g, '').indexOf(_pattern)));
  if (opts) {
    const _keys = _.keys(opts);
    _filtered = _.filter(_filtered, _item => {
      return !!_.find(_keys, _k => _item[_k] === undefined || !opts[_k] || _item[_k] == opts[_k]);
    });
  }
  return _filtered;
}

export function ac_params_factory(param, p, opts_cb, sort_p) {
  return {
    minLength: 2,
    delay: 200,
    source: function(request, add) {
      let term = (request.term || "").replace(/^\s+/, ''),
          opts;

      if (!term) {
        //console.log("no significant symbols in term!");
        add(null);
        return;
      }

      if (opts_cb) {
        let _cbto = typeof opts_cb;
        if (_cbto == 'function') {
          opts = opts_cb();
        } else if (_cbto == "object") {
          opts = opts_cb;
        } else if (_cbto == "string" && !sort_p) {
          sort_p = opts_cb;
        }
      }

      //console.log("window.ac_cache:", window.ac_cache);

      const _minL = this.options.minLength || 2;
      let _idx, _cached;

      if (!window.ac_cache) window.ac_cache = {};
      if (!window.ac_cache[param]) window.ac_cache[param] = {};

      _cached = window.ac_cache[param][term];

      if (!_cached) {
        _idx = term.substr(0, _minL).toLowerCase(); // cache index
        _cached = window.ac_cache[param][_idx];
      } else {
        let resp = _cacheFilter(_cached, term, opts);

        add(resp.length ? resp : null);
        return;
      }

      let op_params = {
        get_query: {q: encodeURIComponent(_idx)},
        id: param
      };

      p.op('suggest', null, op_params).then(response => {
        //console.log("resp", response);
        let resp = null;

        if (_.isArray(response.data)) {
          let d;
          if (sort_p) {
            //sort
            if (sort_p == "name") {
              d = response.data.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
              });
            }
          }

          if (!d) d = response.data;

          resp = d.map(el => {
            if (param == "section") {
              let val = [el.number, el.title].join(" ");
              return {label: val, value: val, el};
            }

            return _.reduce(_.keys(opts), (a, k) => {
              if (el[k] !== undefined) a[k] = el[k];
              return a;
            }, {label: el.name, value: el.name});
          });
        }

        if (_idx) {
          window.ac_cache[param][_idx] = resp;
          resp = _cacheFilter(resp, term, opts);
        }

        add(resp);
      });
    }
  }
};

// download URL for package items and individual files (documents)
export function item_download_url(item, name) {
  if (item.file_id)
    return '/fileurl/' + item.file_id
        + '?url=' + encodeURIComponent(item.url)    // SUB-1063
        + '&name=' + encodeURIComponent(name || item.name); // SUB-1009
  if (item.url) // SUB-2551 some sample packages have package items without file_id
    return item.url;
}

export function download_factory(cb, opts, fail_cb) {
  return function(e) {
    const template = {
      package: true,
      project: true,
      project_export: true,
      rfi: true,
      default: {
        text: "Your PDF is processing. It should be ready within a minute or two.",
        error: "We're sorry but it looks like we've had a problem creating your download. Please contact our support for help."
      }
    };

    let text_msg = template.default.text,
        error_msg = template.default.error,
        tmpl = opts && opts.template && template[opts.template] && opts.template,
        emit_params = {className: "dark"}; //text: text_msg, tmpl}

    if (tmpl) {
      emit_params.template = tmpl;
      if (template[tmpl].text) text_msg = template[tmpl].text;
      if (template[tmpl].error) error_msg = template[tmpl].error;
    } else if (opts) {
      if (opts.text_msg) text_msg = opts.text_msg;
      if (opts.error_msg) error_msg = opts.error_msg;
    }
    emit_params.text = text_msg;

    if (opts && opts.hasWarningPdf) emit_params.hasWarningPdf = opts.hasWarningPdf;

    pr(e);
    window._spinner && window._spinner.emit("on", emit_params);
    window.locker && window.locker.emit("lock");
    let ajaxTime = Date.now();
    cb()
    .always(function(data) {
      window._spinner && window._spinner.emit("off");
      window.locker && window.locker.emit("dismiss");
      if (opts.template || opts.download_type) {
        buildsite.logEvent({
          category: 'api',
          action: (opts.template || opts.download_type)+':download',
          value: Math.round((Date.now() - ajaxTime)/1000)
        });
      }
    })
    .done(function(resp) {
      if (resp.data && resp.data.url) {
        if (["package", "project"].indexOf(opts.template) != -1) {
          buildsite.user().ac_track_event("Downloaded " + (opts.template.replace(/^(.{1})/, (x) => x.toUpperCase())));
        }
        let a = document.createElement('A');
        a.href = resp.data.url;
        a.download = resp.data.url.substr(resp.data.url.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        alertify.error("File generation complete, but url absent");
      }
    })
    .fail(function(a,b,c) {
      if (fail_cb && typeof fail_cb == "function") {
        fail_cb(a,b,c);
      } else {
        if (window.modal && $("#dlgModal").modal) {
          window.modal(DownloadFailDlg,{msg: error_msg});
        } else {
          alertify.error(template.default.error);
        }
      }
    });
  }
};


export function getTZInfo() {
  const date = new Date(),
    tz = date.getTimezoneOffset(),
    tzCode = date.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[3];
  let tzName;

  try {
    tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    tzName = tzCode;
  }

  return { tz, tzName, tzCode };
}


export function download_object({id, type, op_details, op_ext, e}) {
  if ('function' !== typeof buildsite[type]) {
    pr(e);
    return false;
  }

  let {tz, tzName} = getTZInfo(),
      _details = _.assign({ifModified: false, tz, tzName}, op_details),
      dFactoryOpts = {template: type};

  if (op_details && op_details.hasWarningPdf) {
    dFactoryOpts.hasWarningPdf = op_details.hasWarningPdf;
    delete _details.hasWarningPdf; //no need to give it to op 'download'
  }

  download_factory(
    () => buildsite[type](id).op("download", _details, op_ext),
    dFactoryOpts
  )(e);
}


export function auto_suffix(title, titles) {
  if (!titles || titles.length == 0) return title;

  let new_title = title;

  if (!titles.filter(el => el == new_title).length) return new_title;

  let m = new_title.match(/ copy (\d+)$/);
  let index = (m && m[1]) ? m[1] : 1;
  let tryouts = 500;

  new_title = new_title.replace(/ copy \d+$/,""); // remove trailing copy xxx
  while (tryouts-- > 0) {
    let t = new_title + " copy " + index++;
    if (!titles.find(el => el === t)) return t;
  }
  console.log("auto_suffix surrender:", title);
  return title;
};


export function fileIsViewable(file) {
  return !!(file && file.mime_type && (file.mime_type.match(/\/pdf$/) || file.mime_type.match(/^image\/(png|gif|jpe?g)$/)));
};


export function isRoObject(object, objectStatus) {
  return (
    object.status != (objectStatus ? objectStatus.LIVE : "live")
    || !object.access
    || [ACCESS_LEVEL.OWN, ACCESS_LEVEL.WRITE].indexOf(object.access.access_level) == -1
  ) ? true : false;
};


export function set_error_factory(context, templates) {
  return function(err_name) {
    console.log("set_error:", err_name);
    if (templates[err_name]) {
      this.setState({error_message: templates[err_name]});
    } else {
      console.log("set_error: no handler for code", err_name);
    }
  }.bind(context);
}

export function showDialog(DialogClass, dialogProps, dialogClass) {
  return function(e) {
    pr(e);
    window.modal(DialogClass, dialogProps, dialogClass);
  };
}

export function hideModal(e) {
  pr(e);
  window.modal('hide');
}

export function packageHighlighted(preg) {
    return (preg.flow_state
        && (preg.flow_state != "self-assigned")
        && ((preg.flow_active && !preg.flow_last_ack)
            || (preg.flow_last_update && preg.flow_last_update.dt_epoch &&
               (preg.flow_last_ack < preg.flow_last_update.dt_epoch)
           )
        )
    );
}

export function projectHighlighted(project) {
    return project.highlight ? project.highlight._update_highlight : false;
}

/* https://stackoverflow.com/a/901144 */
export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function fileSizeHuman(fsize) {
    let metrics = ['bytes', 'Kb', 'Mb'],
        metric = '';

    while (metrics.length) {
        metric = metrics.shift();
        if (fsize <= 1000 || !metrics.length) break;
        fsize /= 1024;
    }

    fsize = Math.ceil(fsize * 10) / 10;

    return `${fsize} ${metric}`;
}

export function pr(e, stopProp) {
    if (e) {
        if ("function" == typeof e.preventDefault) e.preventDefault();
        if (stopProp && "function" == typeof e.stopPropagation) e.stopPropagation();
        return 1;
    }
    return 0;
}

export function splitItems(o) {
    return (o.registry || {}).split_original_items
        ? `${o.registry.split_items}/${o.registry.split_original_items} original items`
        : null;
}

// https://builds.atlassian.net/browse/SUB-695
export function package_down_resolved(pkg) {
  if (!pkg) return false;
  let reg = pkg.registry;

  if (reg.flow_state && reg.flow_state == "unassigned" && !reg.flow_active) return true;
  if (reg.flow_down_resolved) return true;
  if (reg.flow_down && !reg.flow_down_resolved) return false;
  if (!reg.flow_down) return true;
  return false;
}

// https://builds.atlassian.net/browse/SUB-695
export function package_up_resolved(pkg) {
  if (!pkg) return false;
  let pd = pkg.package_data, reg = pkg.registry,
      pt = pd && pd.meta && pd.meta.package_type;

  // package//list output does not contain .package_data,
  // but has .meta (i.e. it's higher in the data structure,
  // which is confusing, but makes sense in package//list context)
  if (!pt) { pt = pkg.meta && pkg.meta.package_type; }

  if (reg.flow_state && reg.flow_state == "unassigned" && !reg.flow_active) return true;
  if (reg.flow_up_resolved) return true;
  if (pt && pt == "for_information" && reg.flow_up) return true;
  return false;
}


export function amountToString(amount) {
    let num = (amount + '').replace(/\d\d$/, c => "." + c);
    let patt = /\d(?=\d{3}[,\.])/g;
    let a;

    while (a != num) {
        a = num;
        num = num.replace(patt, c => c + ",");
    }

    return num;
};

import planSpecs from '../doc/billing/spec.json';

export { default as planSpecs } from '../doc/billing/spec.json';

import dfns_getTime from 'date-fns/get_time';

export function getEpoch(date) {
  date = date || new Date();
  return Math.floor(dfns_getTime(date) / 1000);
}

export function user_not_reged() {
  let u = buildsite.user().data;
  if (!u || !u.status) return true;
  if (["ghost","initial"].indexOf(u.status) > -1) return true;
  return false;
}

export function getLocationState() {
  return {
    pathname: window.location.pathname,
    search: window.location.search
  }
}

export function getPricingUrl(plan_id, subscribe) {
  let url = _.get(planSpecs, ['core_plans', plan_id, 'pricing_url'], planSpecs.default_pricing_url);

  return subscribe ? `${url}?subscribe` : url;
}

export function getPlanSpecs(plan) {
  return planSpecs.core_plans[plan] || {};
}

// save current values of days as defaults if they are in project and calculator is unfold
export async function saveProjectDaysFields(project_id, state) {
  if (project_id && state.unfold) {
    let object = buildsite.project(project_id);
    let op_details = {
      ['project_data/date_calculator_defaults']: _.pick(
        state,
        ["fabrication_days", "delivery_days", "design_review_days", "gc_review_days", "needed_on_site_date"]
      )
    };
    return object.op('set_key', op_details);
  }
}

export function onboardingEmit(evt) {
  let wb = window.buildsite;
  if (wb && wb.onboarding) wb.onboarding.emit(evt);
  return true;
}

export function reopenClick(e) {
  return pr(e) && onboardingEmit("onboarding.on") && onboardingEmit("onboarding.checklist.open");
}

export function getScrollBarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.position = 'absolute'; //to not trigger resize event
  outer.style.top = '0';
  outer.style.left = '0'
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export function headerRespectScrollBar(tableSelector, containerSelector, headerSelector, sbWidth) {
  if (!document) return;

  const table = document.querySelector(tableSelector),
    theight = table && table.clientHeight && table.clientHeight,
    containerR = document.querySelector(containerSelector),
    cheight = containerR && containerR.clientHeight && containerR.clientHeight;

  const head = document.querySelector(headerSelector);
  if (!head) return;
  if ( theight && cheight && theight > cheight ) {
    return head.style.paddingRight = sbWidth + 'px';
  } else return head.style.paddingRight = '0px';
}

export function isSpecBook(file, projectData) {
  if (!file) return false;
  let isProjectDataSpec = _.get(projectData, 'specbooks', {})[file.file_id];

  return isProjectDataSpec || file.role && file.role == FILE_ROLE.SPECBOOK;
}

export function getButtonText(e) {
  if (!e) return '';
  return e.currentTarget.textContent || '';
}

import Suspense from './suspense';

//can be used only in window.modal(suspense(***)) construction, use 'ui-components/suspense' in render
export const suspense = (Comp, noFallback) => props => <Suspense noFallback={noFallback}><Comp {...props}/></Suspense>
